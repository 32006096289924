import React from "react";
import { useUserState } from "../../../contexts/user/user.context";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid } from "@mui/material";
import { PreferenceComponent } from "./common/preference.component";
import { RealtorsPreferenceComponent } from "./realtors/realtors.preference.component";
interface PreferencesComponentProps {}

//// Component for Realtor preferences FOR NOW, may be used in the future for other account types
export const PreferencesMainComponent: React.FC<
   PreferencesComponentProps
> = ({}) => {
   const { classes } = useStyles();
   const { user } = useUserState();

   if (user && user.isRealtor !== null && user.isRealtor === "1") {
      return <RealtorsPreferenceComponent />;
   } else if (user && user.isRealtor !== null && user.isRealtor === "0") {
      return <></>;
   } else {
      return <></>;
   }
};

const useStyles = makeStyles({ name: "PreferencesMainComponent" })((theme) => ({
   mainContainerRealtor: {
      display: "flex",
      width: "100%",
      paddingLeft: "25px",
      paddingRight: "25px",
   },
   contentContainer: {
      marginTop: "30px",
      display: "flex",
      gap: "20px",
   },
   heading: {
      fontSize: "18px",
      fontWeight: "bold",
      letterSpacing: "0.45px",
   },
}));
