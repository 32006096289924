import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { styled } from "@mui/material";
import { SwitchProps, Switch } from "@mui/material";
import { useTheme } from "@mui/material";
type Props = {
   toggleState: boolean;
   APICallback?: (state: boolean) => void;
   preferenceText: String;
};

export const PreferenceComponent: React.FC<Props> = ({
   toggleState,
   preferenceText,
   APICallback,
}) => {
   const { classes } = useStyles();

   const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;
      if (APICallback) {
         APICallback(newValue);
      }
   };

   const switchProps: SwitchProps = {
      checked: toggleState,
      onChange: handleToggleChange,
   };
   return (
      <>
         <Grid container direction="row" display="flex" gap={"10px"}>
            <Grid item sx={{ position: "relative", top: "1px" }}>
               <IOSStyleSlider {...switchProps} />
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
               <div className={classes.preferenceText}>{preferenceText}</div>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "PreferencesComponent" })((theme) => ({
   preferenceText: {
      fontSize: "18px",
      letterSpacing: "0.45px",
   },
}));

const IOSStyleSlider = styled((props: SwitchProps) => (
   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
   width: 42,
   height: 26,
   padding: 0,
   "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
         transform: "translateX(16px)",
         color: "#fff",
         "& + .MuiSwitch-track": {
            backgroundColor: "#D63F94",
            opacity: 1,
            border: 0,
         },
         "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
         },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
         color: "#33cf4d",
         border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
         color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
         opacity: 0.7,
      },
   },
   "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
   },
   "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
         duration: 500,
      }),
   },
}));
