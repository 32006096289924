import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
   useUserState,
   useUserDispatch,
   UserActions,
} from "../../../../contexts/user/user.context";
import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid, useTheme, Snackbar, Alert } from "@mui/material";
import { PreferenceComponent } from "../common/preference.component";
import { RealtorPreferenceTooltip } from "./realtor.preference.tooltip";
import { updateRealtorTouringAgreementToggleValue } from "../../../../contexts/api/api.functions";

type Props = {};

export const RealtorsPreferenceComponent = ({}) => {
   const { classes } = useStyles();
   const theme = useTheme();
   const { user } = useUserState();
   const dispatch = useUserDispatch();
   const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [tooltipOpen, setTooltipOpen] = useState(false);
   const [tourAgreementToggleState, setTourAgreementToggleState] =
      useState(true);

   useEffect(() => {
      if (user && user.tourAgreementEnabled) {
         if (user.tourAgreementEnabled === "1") {
            setTourAgreementToggleState(true);
         } else {
            setTourAgreementToggleState(false);
         }
      }
   }, [user, user.tourAgreementEnabled]);

   const updateStoredToggleValue = async (newValue: boolean) => {
      let enabledValue: string;
      if (newValue === true) {
         enabledValue = "1";
      } else {
         enabledValue = "0";
      }
      await updateRealtorTouringAgreementToggleValue({
         enabled: enabledValue,
      }).then((res) => {
         if (res && res.data && res.data.status && res.data.status === true) {
            dispatch({
               type: UserActions.user,
               payload: { ...user, tourAgreementEnabled: enabledValue },
            });
         } else {
            setErrorMessage(
               "An unexpected error has occured while attempting to update a preference value. Please try again later."
            );
            setErrorSnackbarOpen(true);
         }
      });
   };

   return (
      <>
         <Snackbar
            open={errorSnackbarOpen}
            autoHideDuration={4000}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            onClose={() => {
               setErrorSnackbarOpen(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert
               onClose={() => {
                  setErrorSnackbarOpen(false);
               }}
               severity="error"
            >
               {errorMessage}
            </Alert>
         </Snackbar>
         <Grid
            container
            direction={"column"}
            className={classes.mainContainerRealtor}
         >
            <Divider
               orientation="horizontal"
               sx={{
                  display: "flex",
                  width: "100%",
                  borderBottomWidth: "2px",
               }}
               flexItem
            />
            <Grid
               container
               display="flex"
               className={classes.contentContainer}
               direction={"column"}
            >
               <Grid item>
                  <div className={classes.heading}>Preferences</div>
               </Grid>
               <Grid item>
                  <Grid container direction="row" display={"flex"} gap={"10px"}>
                     <Grid item>
                        <PreferenceComponent
                           toggleState={tourAgreementToggleState}
                           preferenceText={"Touring Agreement is required."}
                           APICallback={updateStoredToggleValue}
                        />
                     </Grid>
                     <Grid
                        item
                        display={"flex"}
                        sx={{ position: "relative", top: "1px" }}
                     >
                        <InfoOutlined
                           style={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                              display: "relative",
                           }}
                           onClick={() => {
                              setTooltipOpen(!tooltipOpen);
                           }}
                           // onMouseOver={() => {
                           //    if (tooltipOpen === false) {
                           //       setTooltipOpen(true);
                           //    }
                           // }}
                        />
                     </Grid>
                     <RealtorPreferenceTooltip
                        tooltipOpen={tooltipOpen}
                        setTooltipOpen={setTooltipOpen}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "RealtorsPreferenceComponent" })(
   (theme) => ({
      mainContainerRealtor: {
         display: "flex",
         width: "100%",
         paddingLeft: "25px",
         paddingRight: "25px",
      },
      contentContainer: {
         marginTop: "30px",
         display: "flex",
         gap: "20px",
      },
      heading: {
         fontSize: "18px",
         fontWeight: "bold",
         letterSpacing: "0.45px",
      },
   })
);
