import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import { Grid, Paper, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
export const RealtorPreferenceTooltip: React.FC<{
   tooltipOpen: boolean;
   setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ tooltipOpen, setTooltipOpen }) => {
   const theme = useTheme();
   const { classes } = useStyles();


   /// Removed per Jeremey and Erik, setTimeout to auto-close the tooltip
   // useEffect(() => {
   //    if (tooltipOpen === true) {
   //       setTimeout(() => {
   //          setTooltipOpen(false);
   //       }, 6000);
   //    }
   // }, [tooltipOpen]);

   return (
      <>
         <Grid
            item
            className={classes.modal}
            sx={{ visibility: tooltipOpen ? "visible" : "hidden" }}
         >
            <Paper elevation={3} className={classes.paper}>
               <Grid container direction={"column"} display="flex">
                  <Grid item display={"flex"} sx={{ alignSelf: "flex-end" }}>
                     <CloseIcon
                        fontSize="small"
                        onClick={() => {
                           setTooltipOpen(false);
                        }}
                        style={{
                           color: theme.palette.common.medGray,
                           cursor: "pointer",
                        }}
                     />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     sx={{ paddingLeft: "10px", marginBottom: "15px" }}
                  >
                     <div
                        style={{
                           fontFamily: theme.fonts.secondary.bold,
                           fontWeight: "bold",
                           fontSize: "22px",
                        }}
                     >
                        Touring Agreement
                     </div>
                  </Grid>
                  <Grid
                     item
                     sx={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "15px",
                     }}
                  >
                     <Divider
                        style={{
                           borderBottomWidth: "2px",
                           borderBottomColor: "#A2A2A2",
                           opacity: 1,
                        }}
                     />
                  </Grid>
                  <Grid item>
                     <div
                        style={{
                           lineHeight: "1.5",
                           color: theme.palette.common.medGray,
                           fontSize: "16px",
                           marginLeft: "10px",
                        }}
                     >
                        Touring agreements are non-financial, property-specific
                        agreements that serve as limited agency agreements
                        between property listers and visitors. These agreements
                        are mandatory for scheduling tours, valid only for the
                        listed property, and help agents stay compliant with the
                        NAR settlement agreement. Signed agreements are securely
                        stored in tour cards for easy reference.
                     </div>
                  </Grid>
               </Grid>
            </Paper>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "RealtorsPreferenceTooltip" })(
   (theme) => ({
      modal: {
         "@media screen and (max-width: 1482px)": {
            left: "352px",
            bottom: "160px",
         },
         "@media screen and (min-width: 1483px)": {
            left: "352px",
            bottom: "160px",
         },
         "@media screen and (min-width: 1670px)": {
            left: "-12px",
            bottom: "120px",
         },
         marginLeft: "5px",
         position: "relative",
         bottom: "125px",
      },
      paper: {
         height: "300px",
         borderRadius: "25px",
         padding: "10px",
         width: "400px",
         display: "flex",
      },
   })
);
